import { NextRequest } from "next/server"
import { Locale, Segment } from "./sanity/types"

type ExperimentName = string
type ExperimentVariantName = string
export interface Experiment {
    name: ExperimentName
    variants: {[key: ExperimentVariantName]: { path?: string, percent?: number } }
    path?: string | RegExp
    segments?: Segment[]
    langs?: Locale[]
    onlyIfQueryIncludes?: string[]
    onlyIfQueryDoesNotInclude?: string[]
    deviceTypes?: ("desktop" | "mobile")[]
}
export interface ExperimentVariant {
    // experimentName: ExperimentName
    activeVariant: ExperimentVariantName
    path: string
}
export interface ExperimentVariants {
    [key: ExperimentName]: ExperimentVariant
}

export const EXPERIMENTS: Experiment[] = [
    // {
    //     name: "dk_lbb_fp_force_paid_to_the_point",
    //     path: "/dk/erhverv",
    //     onlyIfQueryIncludes: ["google_sem", "paid", "dk", "meta"],
    //     variants: {
    //         "to-the-point": { path: "/dk/erhverv/frontpage-to-the-point" },
    //     },
    // },
    {
        name: "dk_lbb_fp_sweden_like_paid",
        path: "/dk/erhverv",
        onlyIfQueryIncludes: ["google_sem", "paid", "dk", "meta"],
        variants: {
            "control-to-the-point": { path: "/dk/erhverv/frontpage-to-the-point" },
            "sweden-like": { path: "/dk/erhverv/front-page-se-design-paid" },
        },
    },
    {
        name: "dk_lbb_fp_sweden_like_organic",
        path: "/dk/erhverv",
        onlyIfQueryDoesNotInclude: ["google_sem", "paid", "dk", "meta"],
        variants: {
            "control": { path: "/dk/erhverv" },
            "sweden-like": { path: "/dk/erhverv/front-page-se-design" },
        },
    },

    // {
    //     name: "dk_lbb_free_trial_2",
    //     path: /\/dk\/erhverv.*/,
    //     variants: {
    //         control: { },
    //         "free-trial": { },
    //     },
    // },

    // {
    //     name: "dk_lb_applelike_v4_frontpage",
    //     path: "/dk/privat",
    //     // onlyIfQueryIncludes: ["google_sem", "paid", "dk", "meta"],
    //     variants: {
    //         control: { path: "/dk/privat" },
    //         "applelink-v4": { path: "/dk/privat/apple-like-se-frontpage" },
    //     },
    // },

    // REMEMBER TO ADD EXPERIMENT "path" TO middleware.ts !!
]

export function getExperimentForPath(path: string, requestQuery?: string) {
    try {
        const exactMatch = EXPERIMENTS.find(
            (experiment) =>
                typeof path === "string" && path === experiment.path
                && (experiment.onlyIfQueryIncludes ? experiment.onlyIfQueryIncludes.some(querySubstring => requestQuery?.toLowerCase().includes(querySubstring)) : true)
                && (experiment.onlyIfQueryDoesNotInclude ? !experiment.onlyIfQueryDoesNotInclude.some(querySubstring => requestQuery?.toLowerCase().includes(querySubstring)) : true),
        )
        if (exactMatch) return exactMatch
        const regexMatch = EXPERIMENTS.find(
            (experiment) =>
                experiment.path instanceof RegExp && experiment.path.test(path)
                && (experiment.onlyIfQueryIncludes ? experiment.onlyIfQueryIncludes.some(querySubstring => requestQuery?.toLowerCase().includes(querySubstring)) : true)
                && (experiment.onlyIfQueryDoesNotInclude ? !experiment.onlyIfQueryDoesNotInclude.some(querySubstring => requestQuery?.toLowerCase().includes(querySubstring)) : true),
        )
        if (regexMatch) return regexMatch
    } catch(err) {
        //
    }
    return null
}

export function getExperimentVariantAndUpdate(request: NextRequest, experiment: Experiment): ExperimentVariant {
    // Get variant from cookie
    const assignedVariantName: ExperimentVariantName = request.cookies.get("experiment_" + experiment.name)?.value
    const assignedVariant = experiment.variants[assignedVariantName]
    if (assignedVariant) {
        return {
            activeVariant: assignedVariantName,
            path: assignedVariant.path,
        }
    }

    // Get random variant
    const randomVariantIndex = Math.floor(Math.random() * Object.keys(experiment.variants).length)
    Object.keys(experiment.variants)[randomVariantIndex]
    const variant: ExperimentVariant = {
        activeVariant: Object.keys(experiment.variants)[randomVariantIndex],
        path: experiment.variants[Object.keys(experiment.variants)[randomVariantIndex]]?.path,
    }
    // const variant = Object.entries(experiment.variants)[Math.floor(Math.random()*Object.keys(experiment.variants).length)]

    return variant
}
