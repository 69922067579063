import { Language } from "src/sanity/languages"

const translations = {
    blog: {
        published: {
            dk: "Udgivet",
            en: "Published",
            se: "Publicerad",
            no: "Publisert",
        },
        updated: {
            dk: "Opdateret",
            en: "Updated",
            se: "Uppdaterad",
            no: "Oppdatert",
        },
        byAuthor: {
            dk: "Af",
            en: "By",
            se: "Av",
            no: "Av",
        },
        relatedBlogPostsTitle: {
            dk: "Du vil måske også synes om...",
            en: "You might also like...",
            se: "Du kanske också gillar...",
            no: "Du kommer kanskje også til å like...",
        },
        categories: {
            dk: "Kategorier",
            en: "Categories",
            se: "Kategorier",
            no: "Kategorier",
        },
        showAllCategories: {
            dk: "Alle kategorier",
            en: "All categories",
            se: "Alla kategorier",
            no: "Alle kategorier",
        },
        overviewHeadline: {
            dk: "Lunar Blog",
            en: "Lunar Blog",
            se: "Lunar Blogg",
            no: "Lunar Blogg",
        },
        overviewSubtitle: {
            dk: "Få de bedste tips og tricks til din økonomi, når vi deler ud af dem i vores store blogunivers.",
            en: "Get the best tips and tricks for your finances when we share them in our big blog universe.",
            se: "Få de bästa tipsen och tricken för din ekonomi när vi delar dem i vårt stora blogguniversum.",
            no: "Få de beste tipsene og triksene for din økonomi når vi deler dem i vårt store bloggunivers.",
        },
        overviewSubtitleBusiness: {
            dk: "Få de bedste tips og tricks til din økonomi, når vi deler ud af dem i vores store blogunivers.",
            en: "Get the best tips and tricks for your finances when we share them in our big blog universe.",
            se: "Få de bästa tipsen och tricken för din ekonomi när vi delar dem i vårt stora blogguniversum.",
            no: "Få de beste tipsene og triksene for din bedrifts økonomi når vi deler dem i vårt store bloggunivers.",
        },
        loadNextPage: {
            dk: "Hent flere",
            en: "See more",
            se: "Se mer",
            no: "Last ned flere",
        },
        tableOfContentsTitle: {
            dk: "Indhold",
            en: "Contents",
            se: "Innehåll",
            no: "Innhold",
        },
    },
    currencyConverter: {
        amount: {
            dk: "Beløb",
            en: "Amount",
            se: "Belopp",
            no: "Beløp",
        },
        calculatedAmount: {
            dk: "Beregnet beløb",
            en: "Calculated amount",
            se: "Beräknat belopp",
            no: "Bergent beløp",
        },
        amountPlaceholder: {
            dk: "Indtast beløb",
            en: "Enter amount",
            se: "Ange belopp",
            no: "Tast inn beløp",
        },
        from: {
            dk: "Fra valuta",
            en: "From currency",
            se: "Från valuta",
            no: "Fra valuta",
        },
        to: {
            dk: "Til valuta",
            en: "To currency",
            se: "Till valuta",
            no: "Til valuta",
        },
        popular: {
            dk: "Populære",
            en: "Popular",
            se: "Populära",
            no: "Populær",
        },
        all: {
            dk: "Alle",
            en: "All",
            se: "Allt",
            no: "Alle",
        },
        swap: {
            dk: "Byt om",
            en: "Swap",
            se: "Byt om",
            no: "Bytt om",
        },
    },
    currencyTable: {
        name: {
            dk: "Valutaer",
            en: "Currencies",
            se: "Valutor",
            no: "Valutaer",
        },
        value: {
            dk: "Kurs",
            en: "Rate",
            se: "Kurs",
            no: "Kurs",
        },
        code: {
            dk: "Kode",
            en: "Code",
            se: "Kode",
            no: "Kode",
        },
    },
    downloadOverlay: {
        phoneInvalid: {
            dk: "Ugyldigt telefonnummer",
            en: "Invalid phone number",
            se: "Ogiltigt telefonnummer",
            no: "Ugyldig telefonnummer",
        },
        or: {
            dk: "eller",
            en: "or",
            se: "eller",
            no: "eller",
        },
        scanQrCode: {
            dk: "Scan QR-koden for at hente Lunar gratis.",
            en: "Scan the QR code to download Lunar.",
            se: "Skanna QR-koden för att ladda ner Lunar",
            no: "Skann QR-koden for å laste ned Lunar",
        },
        orGetSms: {
            dk: "Eller indtast dit nummer, og få et download-link.",
            en: "Or type in your number to receive a download link.",
            se: "eller få ett SMS med en länk",
            no: "eller få en SMS med en link",
        },
        phoneNumberLabel: {
            dk: "Mobilnummer",
            en: "Phone number",
            se: "Mobilnummer",
            no: "Mobilnummer",
        },
        phoneNumberPlaceholder: {
            dk: "00 00 00 00",
            en: "00 00 00 00",
            se: "000 000 0000",
            no: "000 00 000",
        },
        emailLabel: {
            dk: "Firma-email",
            en: "Company email",
            se: "Din mailadress",
            no: "Firma-email",
        },
        emailPlaceholderConsumer: {
            dk: "navn@email.dk",
            en: "name@email.com",
            se: "namn@e-post.se",
            no: "navn@e-post.no",
        },
        emailPlaceholderBusiness: {
            dk: "navn@firma.dk",
            en: "name@company.com",
            no: "navn@firma.no",
            se: "namn@foretag.se",
        },
        organisationNumberLabel: {
            dk: "CVR-nummer",
            en: "CVR number",
            se: "Organisationsnummer",
            no: "Organisasjonsnummer",
        },
        organisationNumberPlaceholder: {
            dk: "12345678",
            en: "12345678",
            se: "123456-7890",
            no: "12345678",
        },
        organisationTypeLabel: {
            se: "Bolagsform",
        },
        inputYourEmail: {
            dk: "Indtast din firmamail",
            en: "Enter your e-mail",
            se: "Ange din e-mail",
            no: "Skriv inn din email",
        },
        inputYourPhoneNumber: {
            dk: "Indtast dit telefonnummer",
            en: "Enter your phone number",
            se: "Skriv in ditt telefonnummer",
            no: "Tast inn ditt telefonnummer",
        },
        inputYourCVRNumber: {
            dk: "Indtast dit CVR-nummer",
            en: "Enter your CVR number",
            se: "Skriv in ditt CVR-nummer",
            no: "Tast inn ditt CVR-nummer",
        },
        iDontHaveACVRNumberYet: {
            en: "I don't have a CVR number yet",
            dk: "Jeg har ikke et CVR-nummer endnu",
            se: "Jag har inget organisationsnummer ännu",
            no: "Jeg har ikke et CVR-nummer enda",
        },
        businessSignupDisclaimer: {
            en: "By submitting this form, you give us permission to contact you by phone and email, and to receiving marketing communication. We promise not to overwhelm you with spam. You also have the option to withdraw your consent at any time.",
            dk: "Ved at indsende denne formular giver du os tilladelse til at kontakte dig via telefon og email, og til at modtage markedsføringskommunikation. Vi lover ikke at spamme dig. Du har også mulighed for at tilbagekalde dit samtykke til enhver tid.",
            se: "Genom att skicka in detta formulär ger du oss tillåtelse att kontakta dig via telefon och mail samt att skicka kommunikation i marknadsföringssyfte. Vi lovar att inte spamma dig. Du har möjlighet att återkalla ditt samtycke när som helst.",
            no: "Ved å sende inn dette skjemaet gir du oss tillatelse til å kontakte deg via telefon og e-post, og til å motta markedsføringskommunikasjon. Vi lover ikke å spamme deg. Du har også muligheten til å trekke tilbake ditt samtykke når som helst.",
        },
        businessSignupStage2Title: {
            en: "Tell us about your company.",
            dk: "Fortæl os om din virksomhed.",
            se: "Vilken bolagsform har ditt företag?",
            no: "Fortell oss om din bedrift.",
        },
        businessSignupStage2Description: {
            en: "In order to give you the best possible help with your application, we ask you to give us some information about your company.",
            dk: "For at kunne give dig den bedst mulige hjælp til din ansøgning, beder vi dig om at give os nogle oplysninger om din virksomhed.",
            se: null,
            no: "Lorem ipsum",
        },
        businessSignupStage2CtaText: {
            en: "Continue",
            dk: "Fortsæt",
            se: "Fortsätt med ansökan",
            no: "Last ned og søk nå",
        },
        businessSignupStage3SubTitle: {
            dk: "Tak for dine oplysninger!",
            en: "Thank you for your information!",
            se: "Tack för dina uppgifter!",
            no: "Takk for dine opplysninger!",
        },
        businessSignupStage3Title: {
            dk: "Din ansøgningen fortsætter i Lunar app'en 🚀",
            en: "Your application continues in the Lunar app 🚀",
            se: "Din ansökan fortsätter i Lunar-appen 🚀",
            no: "Din søknad fortsetter i Lunar appen 🚀",
        },
        businessSignupStage3StepsTitle: {
            dk: "Sådan gør du:",
            en: "Here's how:",
            se: "Så här gör du:",
            no: "Slik gjør du:",
        },
        businessSignupStage3Steps: {
            dk: "Download Lunar appen via SMS.\nAnsøg om din erhvervskonto i appen.\nNår du er godkendt, vil din konto være klar til dig i appen.",
            en: "Get Lunar by downloading it via SMS.\nApply for your business account in the app.\nOnce approved, your account will be ready for you in the app.",
            se: "Ladda ner Lunar-appen via det SMS du tagit emot, eller genom att scanna QR-koden.\nAnsök om ett företagskonto i appen.\nOm ansökan godkänns finns ert nya konto tillgängligt direkt i appen.",
            no: "Last ned Lunar-appen via SMS.\nSøk om din bedriftskonto i appen.\nNår du er godkjent, vil din konto være klar til deg i appen.",
        },
        businessSignupDownloadAppTitle: {
            dk: "Eller hent appen ved at scanne QR koden",
            en: "Or download the app by scanning the QR code",
            se: "Eller ladda ner appen genom att scanna QR-koden",
            no: "Eller last ned appen ved å skanne QR-koden",
        },
        businessSignupStage3CVRHint: {
            dk: "Psst! Har du ikke et CVR endnu? Tjek din email om hvordan du kommer i gang, og sparer 20% på oprettelse",
            en: "Psst! Don't have a CVR number yet? Check your email on how to get started and save 20% on setup",
            se: "Psst! Har du inte ett CVR-nummer än? Kolla din e-post om hur du kommer igång och spar 20% på uppstart",
            no: "Psst! Har du ikke et CVR-nummer enda? Sjekk din e-post om hvordan du kommer i gang og sparer 20% på oppstart",
        },
        businessSignupContactMeReceiptTitle: {
            dk: "Tak for din henvendelse!",
            se: "Tack för att du visat intresse i ett företagskonto hos oss!",
            no: "Takk for at du viste interesse i en bedriftskonto hos oss!",
            en: "Thank you for your interest in a business account with us!",
        },
        businessSignupContactMeReceiptSubtitle: {
            dk: "Vi kontakter dig indenfor de næste dage.",
            se: "Vi kommer att kontakta dig inom de närmsta dagarna.",
            no: "Vi vil kontakte deg i løpet av de neste dagene.",
            en: "We will contact you within the next few days.",
        },
        getStartedCta: {
            dk: "Kom i gang nu",
            en: "Get started now",
            se: "Kom igång nu",
            no: "Kom i gang nå",
        },
        consumerSignupDisclaimer: {
            en: "When you submit the form, we'll send you an email to get started. By submitting, you agree to receive marketing emails. You can opt out any time by contacting us, and we promise not to spam you.",
            dk: "Når du indsender formularen, sender vi dig en e-mail med en kort guide til at komme i gang. Ved at indsende formularen, accepterer du at modtage marketing-e-mails. Du kan til enhver tid framelde dig ved at kontakte os, og vi lover ikke at spamme dig.",
            se: "När du fyllt i formuläret skickar vi ett mail till dig. Genom att skicka formuläret samtycker du till att ta emot mail i marknadsföringssyfte. Du kan när som helst avsäga dig detta genom att kontakta oss. Vi lovar att inte spamma dig. ",
            no: "Når du sender inn skjemaet, sender vi deg en e-post med en kort guide for å komme i gang. Ved å sende inn skjemaet, samtykker du i å motta markedsførings-e-poster. Du kan når som helst melde deg av ved å kontakte oss, og vi lover å ikke spamme deg.",
        },
        sasHeadline: {
            en: "Apply for your SAS EuroBonus Lunar card in minutes and start earning points today.",
            dk: "Ansøg om dit SAS EuroBonus Lunar-kort på få minutter, og begynd at optjene point i dag.",
            se: "Ansök om ditt SAS EuroBonus Lunar-kort på några minuter och börja tjäna poäng idag.",
            no: "Søk om ditt SAS EuroBonus Lunar-kort på få minutter og begynn å tjene poeng i dag.",
        },
        sasSubtitle: {
            en: "Enter your email to get started.",
            dk: "Indtast din e-mail for at komme i gang.",
            se: "Ange din mailadress för att komma igång.",
            no: "Skriv inn din e-post for å komme i gang.",
        },
    },
    subscriptionList: {
        mostPopular: {
            dk: "Mest populære",
            en: "Most popular",
            se: "Mest populära",
            no: "Mest populære",
        },
        mostPopularBusiness: {
            dk: "Flest vælger",
            en: "Most popular",
            se: "Mest populära",
            no: "Mest populære",
        },
        seeMore: {
            dk: "Se mere",
            en: "See more",
            se: "Se mer",
            no: "Se mer",
        },
        saveXByPayingXYearly: {
            dk: "Spar %s ved at betale årligt",
            en: "Save %s by paying yearly",
            se: "Spar %s ved at betale årligt",
            no: "Spar %s ved at betale årligt",
        },
        paidYearly: {
            dk: "Betales årligt i én betaling",
            en: "Paid yearly in one payment",
            se: "Betala årsvis i en betalning",
            no: "Betal årlig i en betaling",
        },
        payMonthly: {
            dk: "Betal månedligt",
            en: "Pay monthly",
            se: "Betala månadsvis",
            no: "Betal månedlig",
        },
        startupFee: {
            dk: "Plus oprettelsesgebyr.",
            en: "Plus setup fee.",
            se: "",
            no: "",
        },
        noStartupFee: {
            dk: "Gratis oprettelse.",
            en: "No setup fee",
            se: "",
            no: "",
        },
        payYearly: {
            dk: "Betal årligt",
            en: "Pay yearly",
            se: "Betala årsvis",
            no: "Betal årlig",
        },
        yearlyPaymentDisclaimer: {
            dk: "Spar 16% ved at betale årligt",
            en: "Save 16% by paying yearly",
            se: "Spara 16% genom att betala årsvis",
            no: "Spar 16% ved å betale årlig",
        },
    },
    misc: {
        playVideo: {
            dk: "Afspil video",
            en: "Play video",
            se: "Spela filmen",
            no: "Spill av video",
        },
        pricePerMonth: {
            dk: "Pris pr. måned",
            en: "Price per month",
            se: "Pris per månad",
            no: "Pris per måned",
        },
        pricePerMonthShort: {
            dk: "/md.",
            en: "/month",
            se: "/månad",
            no: "/mnd.",
        },
        year: {
            dk: "År",
            en: "Year",
            se: "År",
            no: "År",
        },
        interestRate: {
            en: "Interest rate",
            dk: "Rente",
            se: "Ränta",
            no: "Rente",
        },
    },
    customComponent: {
        copyCouponHeader: {
            dk: "Tilføj promokoden, når du tilmelder dig Lunar",
            en: "Add the promo code when you sign up for Lunar",
            se: "",
            no: "",
        },
        copyCouponSubheader: {
            dk: "Du får Lunar Unlimited gratis resten af året (værdi 417 kr.)",
            en: "You get Lunar Unlimited for free the rest of the year (value 417 kr.)",
            se: "",
            no: "",
        },
        copyCoupon: {
            dk: "Kopier koden",
            en: "Copy code",
            se: "Kopiera länken",
            no: "Kopier koden",
        },
        couponCopied: {
            dk: "Kode kopieret",
            en: "Code copied",
            se: "Länken kopierad",
            no: "Kode kopiert",
        },
        expires: {
            dk: "Tilbuddet udløber om",
            en: "The offer expires in",
            se: "",
            no: "",
        },
        countdownString: {
            dk: "{days} dage {hours} timer {minutes} min {seconds} sek",
            en: "{days} days {hours} hours {minutes} min {seconds} sec",
        },
        helpcenterSearchPlaceholder: {
            dk: "Søg i hjælpecenter",
            en: "Search in help center",
            se: "Sök i hjälpcenter",
            no: "Søk i hjelpesenter",
        },
        helpcenterSearchButton: {
            dk: "Søg",
            en: "Search",
            se: "Sök",
            no: "Søk",
        },
    },
    trustpilot: {
        reviewLabel: {
            dk: "Fremragende",
            en: "Excellent",
            se: "Utmärkt",
            no: "Enestående",
        },
        reviewCount: {
            dk: "19.281 anmeldelser på",
            en: "19,281 reviews on",
            se: "5 554 omdömen på",
            no: "921 anmeldelser på",
        },
    },
}

type TranslationsSource = typeof translations
type TranslationCategories = keyof (typeof translations)

export function getTranslation<T extends TranslationCategories, Lang extends Language>(translationCategory: T, lang: Lang) {
    const slugsInCategory = translations[translationCategory]
    const translation = {}
    Object.entries(slugsInCategory).forEach(([slug, languagesTranslation]) => {
        translation[slug] = languagesTranslation[lang]
    })
    return translation as { [key in (keyof TranslationsSource[T])]: string }
}
